import { createPublicClient, http } from 'viem'
import { pulsechainV4 } from 'viem/chains'
import Web3 from 'web3'

export const multicallAddress = "0x95d4193DfB845BFE92A05780E3E7aE8be2b1e8Bb";
export const factoryAddress = "0xcdbaC726804110ac5b5779fA0A6bd8450809a7B5";
export const contractAddress = "0x0000000000000000000000000000000000000000";
export const WPLSAddress = "0x70499adEBB11Efd915E3b69E700c331778628707";
export const routerAddress = "0x636f6407B90661b73b1C0F7e24F4C79f624d0738";
export const defaultAddress = "0x0000000000000000000000000000000000000000";
export const publicClient = createPublicClient({
    chain: pulsechainV4,
    transport: http()
})
const PLS_PROVIDER_URL = 'https://pulsechain-testnet-rpc.publicnode.com'
export const PlsWeb3 = new Web3(new Web3.providers.HttpProvider(PLS_PROVIDER_URL))

export const imageUrl = 'https://pumpfun.zapto.org:13001/uploads/'

export const apiUrl = 'https://camel-api-09f803eb9061.herokuapp.com'

export const imageUploadUrl = 'https://pumpfun.zapto.org:13001/'