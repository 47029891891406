/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { useAccount, useNetwork } from 'wagmi'
import '../App.css'
import ChadAbi from '../config/CamelPumpAbi.json'
import TokenAbi from '../config/TokenAbi.json'
import RouterAbi from '../config/RouterAbi.json'
import '../styles/MainContainer.css'
import MaxInput from '../components/MaxInput.tsx'
import { readContract, writeContract } from '@wagmi/core'
import { waitForTransaction } from '@wagmi/core'
import Web3 from 'web3'
import { toast } from 'react-hot-toast'
import Footer from '../components/Footer'
import ClaimCard from '../components/ClaimCard.jsx'
import avaxIcon from '../icons/pulse.png'
import TopBar from '../components/TopBar'
import ClipLoader from 'react-spinners/ClipLoader'
import { useQueryParam, StringParam } from 'use-query-params'
import { useWeb3Modal } from '@web3modal/react'
import MyChart from '../components/Chart.jsx'
import CopyIcon from '../icons/copy.svg'
import rot13 from '../../utils/encode.ts'
import { Link } from 'react-router-dom'
import {
  WPLSAddress,
  routerAddress,
  defaultAddress,
  publicClient,
  PlsWeb3,
  imageUrl,
  apiUrl
} from '../utils/constants.ts'
import { SignMessage } from './SignMessage.jsx'

const App = () => {
  let [addressDatas] = useQueryParam('address', StringParam)
  let ChadAddress
  let ref
  if (addressDatas.includes('/?ref=')) {
    ChadAddress = addressDatas.split('/?ref=')[0]
    ref = addressDatas.split('/?ref=')[1]
  } else {
    ChadAddress = addressDatas
  }
  const { address } = useAccount()
  const { chain } = useNetwork()
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const tokenLogo = imageUrl + ChadAddress + '-logo.png'
  const [virtualLp, setVirtualLiquidiity] = useState(0)
  const [maxBuyAmount, setMaxBuyAmount] = useState(0)
  const [tokenAmount, setAmount] = useState(0)
  const [tokenOutAmount, setTokenOutAmount] = useState(0)
  let [accountBalance, setAccountBalance] = useState(0)
  let [inputBalance, setInputBalance] = useState(0)
  let [tokenBalance, setTokenBalance] = useState(0)
  let [tokenAllowance, setTokenAllowance] = useState(0)
  const [virtualTokenLp, setVirtualTokenLp] = useState()
  const [tokenPrice, setTokenPrice] = useState(0)
  let [creating, setCreating] = useState(false)
  const [website, setWebsite] = useState()
  const [twitter, setTwitter] = useState()
  const [telegram, setTelegram] = useState()
  const [inputToken, setInputToken] = useState('PLS')
  const [tokenHolders, setTokenHolders] = useState([])
  const [holderDatas, setTokenHolderDatas] = useState()
  const [transactions, setTransactions] = useState([])
  const [transactionDatas, setTransactionDatas] = useState([])
  const [tokenPriceDatas, setTokenPriceDatas] = useState()
  const [volume, setVolume] = useState(0)
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)
  const [contractAddress, setContractAddress] = useState('')
  const [devAddress, setDevAddress] = useState('')
  const [description, setDescription] = useState('')

  const [refCounts, setRefCounts] = useState(0)
  const [totalRefAmounts, setTotalRefAmounts] = useState(0)
  const [refUserAmount, setRefUserAmout] = useState(0)

  const [lpCreated, setLpCreated] = useState(false)
  const [createdTime, setCreatedTime] = useState(0);
  const [chatHistory, setChatHistory] = useState([])
  const [chatContent,] = useState('')
  const { open } = useWeb3Modal()
  const [firstConnect, setFirstConnect] = useState(false)
  const { isConnected } = useAccount()
  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }
  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const cookies = new Cookies()
  if (ref) {
    if (Web3.utils.isAddress(rot13(ref))) {
      cookies.set('ref', ref)
    }
  }

  let refAddress
  if (cookies.get('ref')) {
    if (Web3.utils.isAddress(rot13(cookies.get('ref')))) {
      refAddress = rot13(cookies.get('ref'))
    }
  } else {
    refAddress = defaultAddress
  }
  const BASE_URL = 'https://camelpump.fun/buy/?address=' + ChadAddress
  const referlink = address
    ? `${BASE_URL}/?ref=${rot13(address)}`
    : `${BASE_URL}/?ref=`

  const copyAddress = address => async e => {
    e.stopPropagation()
    e.preventDefault()
    if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = address
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  function displayTooltip() {
    let timeoutId
    setIsTooltipDisplayed(true)
    timeoutId = setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }

  const onTokenSwap = async () => {
    try {
      setCreating(true)
      let swap
      let sendData
      if (inputToken === 'PLS') {
        if (lpCreated) {
          const path = [WPLSAddress, tokenAddress]
          const timestamp = new Date().getTime() / 1000 + 300
          swap = await writeContract({
            address: routerAddress,
            abi: RouterAbi,
            functionName: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
            value: PlsWeb3.utils.toWei(String(tokenAmount), 'ether'),
            args: [0, path, address, timestamp.toFixed(0)]
          })
        } else {
          swap = await writeContract({
            address: ChadAddress,
            abi: ChadAbi,
            functionName: 'buyToken',
            value: PlsWeb3.utils.toWei(String(tokenAmount), 'ether'),
            args: [refAddress]
          })
        }
        await waitForTransaction({
          hash: swap.hash
        })
        sendData = {
          buyer: address,
          type: 'bought',
          name: tokenName,
          amount: tokenAmount,
          token: tokenAddress,
          contract: contractAddress,
          timestamp: (Date.now() / 1000).toFixed(0)
        }
      } else {
        if (tokenAllowance > 0) {
          if (lpCreated) {
            const path = [tokenAddress, WPLSAddress]
            const timestamp = new Date().getTime() / 1000 + 300
            swap = await writeContract({
              address: routerAddress,
              abi: RouterAbi,
              functionName:
                'swapExactTokensForETHSupportingFeeOnTransferTokens',
              value: PlsWeb3.utils.toWei(String(tokenAmount), 'ether'),
              args: [0, path, address, timestamp.toFixed(0)]
            })
          } else {
            swap = await writeContract({
              address: ChadAddress,
              abi: ChadAbi,
              functionName: 'sellToken',
              args: [
                PlsWeb3.utils.toWei(String(tokenAmount), 'ether'),
                refAddress
              ]
            })
          }

          await waitForTransaction({
            hash: swap.hash
          })
          sendData = {
            buyer: address,
            type: 'Sold',
            name: tokenName,
            amount: tokenAmount,
            token: tokenAddress,
            contract: contractAddress,
            timestamp: (Date.now() / 1000).toFixed(0)
          }
        } else {
          let max =
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          let approve
          let approveAddress
          if (lpCreated) {
            approveAddress = routerAddress
          } else {
            approveAddress = ChadAddress
          }
          approve = await writeContract({
            address: tokenAddress,
            abi: TokenAbi,
            functionName: 'approve',
            args: [approveAddress, max]
          })
          await waitForTransaction({
            hash: approve.hash
          })
        }
        setInputToken(inputToken)
      }
      if (sendData) {
        const response = await fetch(apiUrl + '/api/addHistory', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'error',
          body: JSON.stringify(sendData)
        })
        if (response.status !== 200) {
          const { error } = await response.json()
          throw new Error(error)
        }
      }
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(
        `Successfully ${tokenAmount}   ${inputToken !== 'PLS' ? tokenName : 'PLS'
        } swapped`
      )
    } catch (err) {
      if (
        tokenOutAmount + Number(tokenBalance) >
        (1000000000 * maxBuyAmount) / 100
      ) {
        const remainTokenAmount =
          (1000000000 * maxBuyAmount) / 100 - Number(tokenBalance)
        toast.error(
          "You can't purchase more than " + remainTokenAmount + ' ' + tokenName
        )
      } else {
        toast.error('There is a problem with your Swap. Please try again later')
      }
      setCreating(false)
    }
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        const ChadInfo = await publicClient.readContract({
          address: ChadAddress,
          abi: ChadAbi,
          functionName: 'getFunBasicInfo'
        })
        const GetAllPrices = await publicClient.readContract({
          address: ChadAddress,
          abi: ChadAbi,
          functionName: 'getAllPrices'
        })
        setCreatedTime(Number(ChadInfo[0][1]))
        setTokenPriceDatas(GetAllPrices)
        setTokenName(ChadInfo[1][0])
        setTokenSymbol(ChadInfo[1][1])
        setTokenAddress(ChadInfo[2][1])
        setVirtualLiquidiity(Number(ChadInfo[0][6]) / 10 ** 18)
        setVirtualTokenLp(Number(ChadInfo[0][4]) / 10 ** 18)
        setTokenPrice(Number(ChadInfo[0][9]))
        setMaxBuyAmount(Number(ChadInfo[0][2]))
        setWebsite(ChadInfo[1][2])
        setTwitter(ChadInfo[1][3])
        setTelegram(ChadInfo[1][4])
        setVolume(Number(ChadInfo[0][10]))
        setContractAddress(ChadInfo[2][0])
        setDevAddress(ChadInfo[2][2])
        setRefCounts(Number(ChadInfo[0][8]))
        setTotalRefAmounts(Number(ChadInfo[0][7]) / 10 ** 18)
        setDescription(ChadInfo[1][6])
        setLpCreated(ChadInfo[4])
        if (address) {
          let accountBalance = await PlsWeb3.eth.getBalance(address)
          accountBalance = PlsWeb3.utils.fromWei(accountBalance, 'ether')
          setAccountBalance(accountBalance)
          if (inputToken === 'PLS') {
            setInputBalance(accountBalance)
          } else {
            setInputBalance(tokenBalance)
          }
          const refUserAmounts = await readContract({
            address: ChadAddress,
            abi: ChadAbi,
            functionName: 'refAmounts',
            args: [address]
          })
          setRefUserAmout(Number(refUserAmounts) / 10 ** 18)
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (creating === false) {
      FetchData()
    }
  }, [
    chain?.id,
    creating,
    ChadAddress,
    address,
    PlsWeb3.eth,
    PlsWeb3.utils,
    inputToken,
    tokenBalance
  ])

  const getApi = async () => {
    const GetAllPrices = await publicClient.readContract({
      address: ChadAddress,
      abi: ChadAbi,
      functionName: 'getAllPrices'
    })
    setTokenPriceDatas(GetAllPrices)

    try {
      await fetch(apiUrl + `/api/getOne/${ChadAddress}`, {
        method: 'GET'
      }).then(async res => {
        let data = await res.json()
        if (!data.message) {
          let history
          let historyData = []
          for (let i = 0; i < data?.length; i++) {
            let sender = data[i].sender
            let content = data[i].content
            let currentDate = Date.now()
            let date = currentDate / 1000 - Number(data[i].timestamp)
            if (date > 86400) {
              date = (date / 86400).toFixed(0) + ' days ago'
            } else if (date > 3600) {
              date = (date / 3600).toFixed(0) + ' hours ago'
            } else if (date > 0) {
              date = (date / 60).toFixed(0) + ' mins ago'
            } else {
              date = ' just now'
            }
            let userAvatarUrl = imageUrl + `profile-${sender}.png`
            history = {
              Sender: sender,
              Content: content,
              Date: date,
              avatar: userAvatarUrl
            }
            historyData.push(history)
          }
          setChatHistory(historyData)
        }
      })
    } catch (e) {
      console.error(e)
    }

    try {
      await fetch(
        `https://api.scan.v4.testnet.pulsechain.com/api/v2/addresses/${ChadAddress}/transactions`,
        {
          method: 'GET'
        }
      ).then(async res => {
        let data = await res.json()
        if (!data.message) {
          setTransactions(data.items)
        }
      })
    } catch (e) {
      console.error(e)
    }
    try {
      await fetch(
        `https://api.scan.v4.testnet.pulsechain.com/api/v2/tokens/${tokenAddress}/holders`,
        {
          method: 'GET'
        }
      ).then(async res => {
        let data = await res.json()
        if (!data.message) {
          setTokenHolderDatas(data.items)
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => getApi(), 10000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let id
        if (inputToken === 'PLS') {
          id = '1'
        } else {
          id = '0'
        }
        let amounts
        if (tokenAmount > 0) {
          if (lpCreated) {
            if (id === '1') {
              path = [WPLSAddress, tokenAddress]
            } else {
              path = [tokenAddress, WPLSAddress]
            }
            amounts = await publicClient.readContract({
              address: routerAddress,
              abi: RouterAbi,
              functionName: 'getAmountsOut',
              args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), path]
            })
          } else {
            amounts = await publicClient.readContract({
              address: ChadAddress,
              abi: ChadAbi,
              functionName: 'plsOrTokenAmount',
              args: [PlsWeb3.utils.toWei(String(tokenAmount), 'ether'), id]
            })
          }
        } else {
          amounts = 0
        }


        setTokenOutAmount(Number(amounts) / 10 ** 18)
      } catch (e) {
        console.error(e)
      }
    }
    if (creating === false) {
      FetchAmount()
    }
  }, [inputToken, tokenAmount, creating])

  const chanageCurrency = async () => {
    if (inputToken === 'PLS') {
      setInputToken('Token')
      setInputBalance(tokenBalance)
      setAmount(tokenOutAmount)
    } else {
      setInputToken('PLS')
      setInputBalance(accountBalance)
      setAmount(tokenOutAmount)
    }
  }

  const buyChangeCurrency = async () => {
    setInputToken('PLS')
    setInputBalance(accountBalance)
    setAmount(tokenOutAmount)
  }

  const sellChangeCurrency = async () => {
    setInputToken('PLS')
    setInputBalance(tokenBalance)
    setAmount(tokenOutAmount)
  }

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let amounts
        amounts = await readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'balanceOf',
          args: [address]
        })
        let allowance
        let approveAddress
        if (lpCreated) {
          approveAddress = routerAddress
        } else {
          approveAddress = ChadAddress
        }
        allowance = await readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'allowance',
          args: [address, approveAddress]
        })
        setTokenAllowance(Number(allowance) / 10 ** 18)
        setTokenBalance(PlsWeb3.utils.fromWei(String(amounts), 'ether'))
      } catch (e) {
        console.error(e)
      }
    }
    if (address && tokenAddress) {
      FetchAmount()
    }
  }, [tokenAddress, address, creating])

  useEffect(() => {
    const FetchHolder = async () => {
      try {
        let tokenHolder
        let tokenHolders = []
        for (let i = 0; i < holderDatas?.length; i++) {
          console.log(holderDatas[i])
          tokenHolder = {
            address: holderDatas[i].address.hash,
            value: (Number(holderDatas[i].value) / (10 ** 27)) * 100
          }
          tokenHolders.push(tokenHolder)
        }
        setTokenHolders(tokenHolders)
      } catch (e) {
        console.error(e)
      }
    }
    const FetchTransaction = async () => {
      try {
        let transaction
        let transactionData = []
        for (let i = 0; i < transactions?.length; i++) {
          if (
            (transactions[i].method.includes('buyToken') ||
            transactions[i].method.includes('sellToken')) && (transactions[i].status === true)
          ) {
            let maker = transactions[i].from
            let type
            let amount
            if (transactions[i].method.includes('buyToken')) {
              type = 'Buy'
            } else {
              type = 'Sell'
            }
            amount = tokenPriceDatas[transactions?.length - i].usdAmount
            amount =
              '$' +
              Number(PlsWeb3.utils.fromWei(String(amount), 'ether')).toFixed(6)
            let date = new Date(transactions[i].timestamp).getTime() / 1000
            let currentDate = Date.now()
            date = currentDate / 1000 - date
            if (date > 86400) {
              date = (date / 86400).toFixed(0) + ' days ago'
            } else if (date > 3600) {
              date = (date / 3600).toFixed(0) + ' hours ago'
            } else if (date > 0) {
              date = (date / 60).toFixed(0) + ' mins ago'
            } else {
              date = ' just now'
            }
            if (date < 0) {
              date = 'just now'
            }
            let tx = transactions[i].id
            transaction = {
              Maker: maker,
              Type: type,
              Amount: amount,
              Date: date,
              Tx: tx
            }
            transactionData.push(transaction)
          }
        }
        setTransactionDatas(transactionData)
      } catch (e) {
        console.error(e)
      }
    }
    if (tokenAddress) {
      if (holderDatas?.length > 0) {
        FetchHolder()
      } else if (transactions?.length > 0) {
        FetchTransaction()
      } else {
        getApi()
      }
    }
  }, [holderDatas, tokenAddress, transactions])

  // Pagination State and Logic for Holders Table
  const [currentHolderPage, setCurrentHolderPage] = useState(1)
  const [holderTotalPages, setHolderTotalPages] = useState(0)
  const holderItemsPerPage = 10
  const [holderPageNumbers, setHolderPageNumbers] = useState([])

  const calculateHolderPageNumbers = (totalPages, currentPage) => {
    let pages = []

    if (totalPages <= 4) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1)
    } else {
      if (currentPage === 1) {
        pages = [1, 2, '...', totalPages - 1, totalPages]
      } else if (currentPage === totalPages) {
        pages = [1, 2, '...', totalPages - 1, totalPages]
      } else {
        if (currentPage + 1 < totalPages) {
          if (currentPage + 1 === totalPages - 1) {
            pages = [currentPage - 1, currentPage, currentPage + 1, totalPages]
          } else {
            pages = [
              currentPage - 1,
              currentPage,
              currentPage + 1,
              '...',
              totalPages
            ]
          }
        } else if (currentPage < totalPages) {
          pages = [currentPage - 1, currentPage, currentPage + 1]
        } else {
          pages = [1, 2, '...', totalPages - 1, totalPages]
        }
      }
    }

    return pages
  }

  const handleHolderPageChange = newPageNumber => {
    setCurrentHolderPage(newPageNumber)
    setHolderPageNumbers(
      calculateHolderPageNumbers(holderTotalPages, newPageNumber)
    )
  }

  useEffect(() => {
    const totalPages = Math.ceil(tokenHolders.length / holderItemsPerPage)
    setHolderTotalPages(totalPages)
    setHolderPageNumbers(
      calculateHolderPageNumbers(totalPages, currentHolderPage)
    )
  }, [tokenHolders])

  // Pagination State and Logic for Transactions Table
  const [currentTransactionPage, setCurrentTransactionPage] = useState(1)
  const [transactionTotalPages, setTransactionTotalPages] = useState(0)
  const transactionItemsPerPage = 5
  const [transactionPageNumbers, setTransactionPageNumbers] = useState([])

  const calculateTransactionPageNumbers = (totalPages, currentPage) => {
    let pages = []

    if (totalPages <= 4) {
      pages = Array.from({ length: totalPages }, (_, i) => i + 1)
    } else {
      if (currentPage === 1) {
        pages = [1, 2, '...', totalPages - 1, totalPages]
      } else if (currentPage === totalPages) {
        pages = [1, 2, '...', totalPages - 1, totalPages]
      } else {
        if (currentPage + 1 < totalPages) {
          if (currentPage + 1 === totalPages - 1) {
            pages = [currentPage - 1, currentPage, currentPage + 1, totalPages]
          } else {
            pages = [
              currentPage - 1,
              currentPage,
              currentPage + 1,
              '...',
              totalPages
            ]
          }
        } else if (currentPage < totalPages) {
          pages = [currentPage - 1, currentPage, currentPage + 1]
        } else {
          pages = [1, 2, '...', totalPages - 1, totalPages]
        }
      }
    }

    return pages
  }

  const handleTransactionPageChange = newPageNumber => {
    setCurrentTransactionPage(newPageNumber)
    setTransactionPageNumbers(
      calculateTransactionPageNumbers(transactionTotalPages, newPageNumber)
    )
  }

  useEffect(() => {
    const totalPages = Math.ceil(
      transactionDatas.length / transactionItemsPerPage
    )
    setTransactionTotalPages(totalPages)
    setTransactionPageNumbers(
      calculateTransactionPageNumbers(totalPages, currentTransactionPage)
    )
  }, [transactionDatas])

  let progress =
    ((800000000 - (Number(virtualTokenLp) - 200000000)) * 100) / 800000000

  const [selectedOption, setSelectedOption] = useState('Trades')
  const [selectedMobileOption, setSelectedMobileOption] = useState('chart')

  function isMobile() {
    try {
      document.createEvent('TouchEvent')
      return true
    } catch (e) {
      return false
    }
  }
  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="navBar"></div>
            <div className="headerMargin" />
            <>
              <section className="MainDashboard ResponsiveFlexLayout">
                <div className='ClaimBox'>
                  <Link
                    className="back-button"
                    to="/"
                  >
                    &lt; Back
                  </Link>
                  <ClaimCard
                    tokenName={tokenName}
                    Logo={<img src={tokenLogo} />}
                    tokenAddress={tokenAddress}
                    contractAddress={contractAddress}
                    dexAddress="https://pulsex.mypinata.cloud/ipfs/bafybeiesh56oijasgr7creubue6xt5anivxifrwd5a5argiz4orbed57qi/"
                    devAddress={devAddress}
                    dexName="PulseX"
                    tokenSymbol={tokenSymbol}
                    tokenDecimals={18}
                    tokenTotalSupply={1000000000}
                    maxBuyAmount={maxBuyAmount}
                    tokenSupplyUSD={virtualLp}
                    tokenSupplyLiquidity={virtualTokenLp}
                    tokenPrice={tokenPrice}
                    tokenUnsoldTokens={'Burned 🔥'}
                    tokenCover={tokenLogo}
                    website={website}
                    telegram={telegram}
                    twitter={twitter}
                    volume={volume}
                    description={description}
                    createdTime={createdTime}
                  />
                </div>
              </section>
            </>
            {isMobile() && (
              <div className="token-info-label-header aligned-left claim-card-empty-header">
                <p style={selectedMobileOption === "buy-sell" ? { borderBottom: '3px solid #00a337' } : {}} onClick={() => setSelectedMobileOption('buy-sell')}>
                  Buy / Sell
                </p>
                <p style={selectedMobileOption === "chart" ? { borderBottom: '3px solid #00a337' } : {}} onClick={() => setSelectedMobileOption('chart')}>
                  Chart
                </p>
              </div>
            )}
            <div className="MainDashboard ResponsiveFlexLayout">
              <section className="ClaimRightColumn" style={isMobile() ? (selectedMobileOption === "chart" ? {} : { display: "none" }) : ({})}>
                <div className="claim-card">
                  <div className="w-full rounded-xl">
                    <p>Token Price Graph</p>
                    <div>
                      {lpCreated ? (
                        <iframe
                          src={`https://dexscreener.com/avalanche/${tokenAddress}?embed=1&trades=0&info=0&theme=light`}
                          className="chart"
                          title="chart"
                        />
                      ) : (
                        <MyChart data={tokenPriceDatas} />
                      )}
                    </div>
                  </div>
                </div>
                <br />
                <div className="token-info-label-header aligned-left claim-card-empty">
                  <p style={selectedOption === "Trades" ? { borderBottom: '3px solid #00a337' } : {}} onClick={() => setSelectedOption('Trades')}>
                    Trading History
                  </p>
                  <p style={selectedOption === "Trades" ? {} : { borderBottom: '3px solid #00a337' }} onClick={() => setSelectedOption('Chat')}>
                    Chat History
                  </p>
                </div>
                <div className="claim-card">
                  {selectedOption === 'Trades' && (
                    <>
                      {transactionDatas.length > 0 ? (
                        <div className="w-full rounded-xl">
                          <div>
                            <div className="tradeBox">
                              <p className="token-info-label">Maker</p>
                              <p className="token-info-label">Type</p>
                              <p className="token-info-label">Amount</p>
                              <p className="token-info-label">Date</p>
                              <p className="token-info-label">Transaction</p>
                            </div>
                            <div
                              style={{
                                borderBottom: 'solid 1px #7773',
                                marginTop: '12px',
                                marginBottom: '12px'
                              }}
                            />
                            {transactionDatas
                              .slice(
                                (currentTransactionPage - 1) *
                                transactionItemsPerPage,
                                currentTransactionPage * transactionItemsPerPage
                              )
                              .map(({ Maker, Type, Amount, Date, Tx }) => (
                                <>
                                  <div className="tradeBox">
                                    <div>
                                      <a
                                        className="holderContent"
                                        href={
                                          'https://scan.v4.testnet.pulsechain.com/#/address/' +
                                          Maker
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <p className="tokenLists">
                                          {Maker.slice(0, 5) +
                                            '...' +
                                            Maker.slice(-3)}
                                        </p>
                                      </a>
                                    </div>
                                    <div>
                                      <p
                                        className={
                                          Type === 'Buy'
                                            ? 'tokenLists tokenBuy'
                                            : 'tokenLists tokenSell'
                                        }
                                      >
                                        {Type}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="tokenLists tokenAmount">
                                        {Amount}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="tokenLists tokenDate">{Date}</p>
                                    </div>
                                    <div>
                                      <a
                                        className="holderContent"
                                        href={
                                          'https://scan.v4.testnet.pulsechain.com/#/tx/' + Tx
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <p className="tokenLists">
                                          {Tx.slice(0, 5) + '...' + Tx.slice(-3)}
                                        </p>
                                      </a>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      borderBottom: 'solid 1px #7773',
                                      marginTop: '12px',
                                      marginBottom: '12px'
                                    }}
                                  />
                                </>
                              ))}
                            <div
                              className="flex my-4"
                              style={{ textAlign: 'right' }}
                            >
                              {/* Render the "First Page" button */}
                              <button
                                className="px-2 py-1 mx-1 bg-primary text-secondary rounded-lg border border-primary"
                                onClick={() => handleTransactionPageChange(1)}
                              >
                                &lt;&lt;
                              </button>
                              {/* Render the "Previous Page" button */}
                              <button
                                className="px-2 py-1 mx-1 bg-primary text-secondary rounded-lg border border-primary"
                                onClick={() =>
                                  handleTransactionPageChange(
                                    Math.max(currentTransactionPage - 1, 1)
                                  )
                                }
                              >
                                &lt;
                              </button>
                              {transactionPageNumbers.map((pageNumber, index) => {
                                if (typeof pageNumber === 'number') {
                                  return (
                                    <button
                                      key={pageNumber}
                                      className={`px-2 py-1 mx-1 ${currentTransactionPage === pageNumber
                                        ? 'bg-active text-white'
                                        : 'bg-primary text-secondary'
                                        } rounded-lg border border-primary`}
                                      onClick={() =>
                                        handleTransactionPageChange(pageNumber)
                                      }
                                    >
                                      {pageNumber}
                                    </button>
                                  )
                                } else {
                                  return (
                                    <span
                                      key={pageNumber}
                                      className="px-2 py-1 mx-1 bg-transparent text-secondary rounded-lg border border-primary"
                                    >
                                      ...
                                    </span>
                                  )
                                }
                              })}
                              {/* Render the "Next Page" button */}
                              <button
                                className="px-2 py-1 mx-1 bg-primary text-secondary rounded-lg border border-primary"
                                onClick={() =>
                                  handleTransactionPageChange(
                                    Math.min(
                                      currentTransactionPage + 1,
                                      transactionTotalPages
                                    )
                                  )
                                }
                              >
                                &gt;
                              </button>
                              {/* Render the "Last Page" button */}
                              <button
                                className="px-2 py-1 mx-1 bg-primary text-secondary rounded-lg border border-primary"
                                onClick={() =>
                                  handleTransactionPageChange(transactionTotalPages)
                                }
                              >
                                &gt;&gt;
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {selectedOption === 'Chat' && (
                    <section className="InputSection_Description">
                      {chatHistory.length > 0 ? (
                        <div>
                          <div>
                            {chatHistory.map(
                              ({ Sender, Content, Date, avatar }) => (
                                <>
                                  <div className="chatBox">
                                    <div>
                                      <div className="chat-eth-logo-container relative">
                                        <img src={avatar} className="chat-profile-avatar"
                                          onError={event => {
                                            event.target.src = "/img/moonboy67.png"
                                            event.onerror = null
                                          }} />
                                        &nbsp; &nbsp;
                                        <div>
                                          <div className="top-trending">
                                            <Link
                                              className="chatContent"
                                              to={'/profile/?address=' + Sender}

                                              rel="noreferrer"
                                            >
                                              <p>
                                                {Sender.slice(0, 5) +
                                                  '...' +
                                                  Sender.slice(-3)}
                                              </p>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                class="lucide-icon lucide lucide-external-link h-4 w-4"
                                              >
                                                <path d="M15 3h6v6"></path>
                                                <path d="M10 14 21 3"></path>
                                                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                                              </svg>
                                              &nbsp;
                                            </Link>
                                            &nbsp;
                                            <div>
                                              <p className="chatLists">{Date}</p>
                                            </div>
                                          </div>
                                          <div>
                                            <p className="chatLists">
                                              {Content}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              )
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="ButtonBox">
                        <SignMessage
                          ChadAddress={ChadAddress}
                          sender={address}
                          content={chatContent}
                          timestamp={(Date.now() / 1000).toFixed(0)}
                        />
                      </div>
                    </section>
                  )}
                </div>
                <br />
              </section>
              <section className="ClaimLeftColumn" style={isMobile() ? (selectedMobileOption === "buy-sell" ? {} : { display: "none" }) : ({})}>
                <div className="claim-card">
                  <div className='ConnectWalletBoxButton'>
                    <button
                      onClick={buyChangeCurrency}
                      className="switch-button"
                      style={inputToken === "PLS" ? {} : { background: 'transparent' }}
                    >
                      Buy
                    </button>
                    <button
                      onClick={sellChangeCurrency}
                      className="switch-button"
                      style={inputToken === "PLS" ? { background: 'transparent' } : {}}
                    >
                      Sell
                    </button>
                  </div>
                  <div className="launchpad-progress-container">
                    <section className="InputSection_Description">
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <div className="InputContainer">
                            <MaxInput
                              placeholder="Enter Amount"
                              label=""
                              type="number"
                              changeValue={setAmount}
                              value={tokenAmount}
                            />
                            <img
                              src={inputToken === 'PLS' ? avaxIcon : tokenLogo}
                              className="claim-eth-token-logo"
                            />
                          </div>
                        </section>
                        <div className="LpBalance sub">
                          <span className="launchpad-balance-text">
                            Balance:  {Number(inputBalance).toFixed(2)}{' '}
                            {inputToken === 'PLS' ? 'PLS' : tokenName}
                          </span>
                        </div>
                      </section>
                      <div
                        style={{
                          display: 'flex',
                          gap: '8px',
                          marginTop: '30px'
                        }}
                      >
                        {['1%', '5%', '10%', '50%', 'MAX'].map(value => (
                          <button
                            key={value}
                            onClick={() => setAmount(
                              value === '1%' ? inputBalance / 100
                                : value === '5%' ? inputBalance * 5 / 100
                                  : value === '10%' ? inputBalance * 10 / 100
                                    : value === '50%' ? inputBalance * 50 / 100
                                      : inputBalance
                            )}
                            style={{
                              backgroundColor: '#151515',
                              color: 'white',
                              padding: '5px 10px',
                              border: 'none',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              zIndex: '1'
                            }}
                          >
                            {value}
                          </button>
                        ))}
                      </div>
                    </section>
                  </div>
                  <button
                    onClick={chanageCurrency}
                    className="claim-button"
                    style={{ marginTop: '16px', marginBottom: '16px' }}
                  >
                    ↑ ↓
                  </button>
                  <div className="launchpad-progress-container">
                    <section className="InputSection_Description">
                      <section className="inputPanel">
                        <section className="inputPanelHeader">
                          <div className="InputContainer">
                            <input
                              placeholder=""
                              label=""
                              type="number"
                              value={tokenOutAmount}
                              disabled
                            />
                            <img
                              src={inputToken !== 'PLS' ? avaxIcon : tokenLogo}
                              className="claim-eth-token-logo"
                            />
                          </div>
                        </section>
                        <div className="LpBalance sub">

                        </div>
                      </section>
                    </section>
                  </div>
                  {creating === false ? (
                    address === undefined ?
                      <button
                        className="claim-button"
                        type="submit"
                        onClick={() => {
                          onConnectWallet()
                        }}
                      >
                        Connect
                        <span className="navWallet"> Wallet First</span>
                      </button> :
                      <button onClick={onTokenSwap} className="claim-button" disabled={false}>
                        {inputToken !== 'PLS' && tokenAllowance === 0
                          ? 'Approve token First'
                          : 'Swap Tokens'
                        }
                      </button>
                  ) : (
                    <>
                      <div className="loadingBox">
                        <p className="Text1" style={{ color: 'white' }}>
                          Loading...
                        </p>
                        <ClipLoader
                          color={'#00ccc6'}
                          loading={creating}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </>
                  )}
                </div>

                <br />
                <div className="launchpad-progress-container">
                  <div className="launchpad-progress-text">
                    Bonding Curve Progress: {progress.toFixed(2)}%
                  </div>
                  <div className="launchpad-progress-bar-unfilled left-aligned">
                    <div
                      className="launchpad-progress-bar"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div>
                  <div className="token-info-label-header aligned-left claim-card-empty">
                    Holder Distribution
                  </div>
                  <div className="claim-card">
                    <div className="token-info-item">
                      <span className="token-info-label aligned-left">
                        Holder
                      </span>
                      <span className="token-info-label aligned-right">
                        Percentage
                      </span>
                    </div>
                    <div
                      style={{
                        borderBottom: 'solid 1px #7773',
                        marginTop: '12px',
                        marginBottom: '12px'
                      }}
                    />
                    {tokenHolders
                      .slice(
                        (currentHolderPage - 1) * holderItemsPerPage,
                        currentHolderPage * holderItemsPerPage
                      )
                      .map(({ address, value }) => (
                        <>
                          <div className="holderBox">
                            <a
                              className="holderContent"
                              href={
                                'https://scan.v4.testnet.pulsechain.com/#/address/' +
                                address
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p
                                style={{
                                  textAlign: 'center',
                                  margin: '0px'
                                }}
                                className="tokenLists"
                              >
                                {address.toString().slice(0, 5) +
                                  '...' +
                                  address.toString().slice(-3)}
                              </p>
                            </a>
                            <p
                              style={{
                                textAlign: 'center',
                                margin: '0px',
                                fontSize: '12px',
                                color: '#ddd'
                              }}
                              className="tokenLists"
                            >
                              {value.toFixed(2)}%
                            </p>
                          </div>
                          <div
                            style={{
                              borderBottom: 'solid 1px #7773',
                              marginTop: '12px',
                              marginBottom: '12px'
                            }}
                          />
                        </>
                      ))}
                    <div className="flex my-4" style={{ textAlign: 'right' }}>
                      {/* Render the "First Page" button */}
                      <button
                        className="px-2 py-1 mx-1 bg-primary text-secondary rounded-lg border border-primary"
                        onClick={() => handleHolderPageChange(1)}
                      >
                        &lt;&lt;
                      </button>
                      {/* Render the "Previous Page" button */}
                      <button
                        className="px-2 py-1 mx-1 bg-primary text-secondary rounded-lg border border-primary"
                        onClick={() =>
                          handleHolderPageChange(
                            Math.max(currentHolderPage - 1, 1)
                          )
                        }
                      >
                        &lt;
                      </button>
                      {holderPageNumbers.map((pageNumber, index) => {
                        if (typeof pageNumber === 'number') {
                          return (
                            <button
                              key={pageNumber}
                              className={`px-2 py-1 mx-1 ${currentHolderPage === pageNumber
                                ? 'bg-active text-white'
                                : 'bg-primary text-secondary'
                                } rounded-lg border border-primary`}
                              onClick={() => handleHolderPageChange(pageNumber)}
                            >
                              {pageNumber}
                            </button>
                          )
                        } else {
                          return (
                            <span
                              key={pageNumber}
                              className="px-2 py-1 mx-1 bg-transparent text-secondary rounded-lg border border-primary"
                            >
                              ...
                            </span>
                          )
                        }
                      })}
                      {/* Render the "Next Page" button */}
                      <button
                        className="px-2 py-1 mx-1 bg-primary text-secondary rounded-lg border border-primary"
                        onClick={() =>
                          handleHolderPageChange(
                            Math.min(currentHolderPage + 1, holderTotalPages)
                          )
                        }
                      >
                        &gt;
                      </button>
                      {/* Render the "Last Page" button */}
                      <button
                        className="px-2 py-1 mx-1 bg-primary text-secondary rounded-lg border border-primary"
                        onClick={() => handleHolderPageChange(holderTotalPages)}
                      >
                        &gt;&gt;
                      </button>
                    </div>
                  </div>
                </div>
                <br />
                <div className="claim-card">
                  <div className="token-info-item">
                    <span className="token-info-label mx-auto">
                      <h3 style={{ marginTop: '0px' }}>
                        Earn 50% Affiliate Commission
                      </h3>
                    </span>
                  </div>
                  <div className="token-info-item">
                    <span className="token-info-label">
                      Share this affiliate link with others and automatically start earning 50% of the ChadPump.fun trading fees collected from your referrals across our entire website.
                    </span>
                  </div>
                  <br />
                  <div
                    className="fair-launch-box"
                    style={{ wordBreak: 'break-all' }}
                  >
                    {referlink}
                  </div>
                  <br />
                  <button
                    onClick={copyAddress(referlink)}
                    className="save-button"
                  >
                    {isTooltipDisplayed ? (
                      <span className="tooltiptext center-aligned">Copied</span>
                    ) : (
                      <>
                        Copy
                        <img src={CopyIcon} />
                      </>
                    )}
                  </button>
                  {refUserAmount > 0 ? (
                    <div>
                      <div className="RefBox">
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          Your Total Refferal Amounts:
                        </p>
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          {totalRefAmounts} pls
                        </p>
                      </div>
                      <hr />
                      <div className="RefBox">
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          Total Referrals:
                        </p>
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          {refCounts}
                        </p>
                      </div>
                      <hr />
                      <div className="RefBox">
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          Your referral amounts:
                        </p>
                        <p
                          style={{
                            textAlign: 'center',
                            margin: '0px'
                          }}
                          className="tokenLists"
                        >
                          {refUserAmount} pls
                        </p>
                      </div>
                      <hr />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </section>
            </div>
          </div>
        }
      </div>
      <Footer />
    </div>
  )
}

export default App
