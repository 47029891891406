import React from 'react';
import '../styles/Input.css';

interface InputProps {
  value: string;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  placeholder: string;
  maxLength?: number;
}

const Input: React.FC<InputProps> = ({ value, type, onChange, label, placeholder, maxLength }) => {
  return (
    <div className="InputContainer">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        required
      />
    </div>
  );
};

export default Input;