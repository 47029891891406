/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CopyIcon from '../icons/copy.svg'
import { Link } from 'react-router-dom'
import WebsiteIcon from '../icons/website.png'
import TelegramIcon from '../icons/telegram.png'
import TwitterIcon from '../icons/x-icon.svg'

const ChadPumpInfoSection = ({
  tokenSupplyUSD,
  tokenPrice,
  volume,
  tokenCreated
}) => (
  <>
    <div className="fair-launch-container">
      <div className="fair-launch-box fairLaunchBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Price
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          ${(tokenPrice / 10 ** 12).toFixed(8)}
        </span>
      </div>
      <div className="fair-launch-box fairLaunchBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Marketcap
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          $
          {new Intl.NumberFormat().format((tokenPrice * 1000000000) / 10 ** 12)}
        </span>
      </div>
      <div className="fair-launch-box fairLaunchBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Liquidity
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          ${new Intl.NumberFormat().format(tokenSupplyUSD.toFixed(0))}
        </span>
      </div>
      <div className="fair-launch-box fairLaunchBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Volume
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          ${(volume / 10 ** 18).toFixed(5)}
        </span>
      </div>
      <div className="fair-launch-box fairLaunchBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Token Created
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          {tokenCreated ? tokenCreated : 'N/A'}
        </span>
      </div>
    </div>
  </>
)


const ChadPumpInfoMobileSection = ({
  tokenSupplyUSD,
  tokenPrice,
  volume,
  tokenCreated
}) => (
  <>
    <div className="fair-launch-container-mobile">
      <div className="fair-launch-box fairLaunchMobileBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Price
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          ${(tokenPrice / 10 ** 12).toFixed(8)}
        </span>
      </div>
      <div className="fair-launch-box fairLaunchMobileBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Marketcap
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          $
          {new Intl.NumberFormat().format((tokenPrice * 1000000000) / 10 ** 12)}
        </span>
      </div>
      <div className="fair-launch-box fairLaunchMobileBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Liquidity
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          ${new Intl.NumberFormat().format(tokenSupplyUSD.toFixed(0))}
        </span>
      </div>
      <div className="fair-launch-box fairLaunchMobileBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Volume
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          ${(volume / 10 ** 18).toFixed(5)}
        </span>
      </div>
      <div className="fair-launch-box fairLaunchMobileBoxStyle">
        <span className="fair-launch-label" style={titleStyle}>
          Token Created
        </span>
        <span className="fair-launch-value" style={valueStyle}>
          {tokenCreated ? tokenCreated : 'N/A'}
        </span>
      </div>
    </div>
  </>
)

const titleStyle = {
  color: 'lightgray',
  fontSize: '12px',
  fontWeight: 'bold'
}

const valueStyle = {
  color: 'rgb(232, 230, 227)',
  fontSize: '18px',
  fontWeight: 'bold'
}

const ClaimCard = ({
  tokenName,
  Logo,
  tokenAddress,
  contractAddress,
  dexAddress,
  devAddress,
  dexName,
  tokenSymbol,
  tokenDecimals,
  tokenTotalSupply,
  maxBuyAmount,
  tokenSupplyUSD,
  tokenSupplyLiquidity,
  tokenPrice,
  tokenUnsoldTokens,
  tokenCover,
  website,
  telegram,
  twitter,
  volume,
  description,
  tokenCreated,
  createdTime
}) => {
  if (website) {
    if (!website.includes('https')) {
      website = 'https://' + website
    }
  }
  if (telegram) {
    if (!telegram.includes('https')) {
      telegram = 'https://' + telegram
    }
  }
  if (twitter) {
    if (!twitter.includes('https')) {
      twitter = 'https://' + twitter
    }
  }
  const time = new Date(createdTime * 1000).toLocaleDateString();
  const bannerUrl = 'url("' + tokenCover + '")'
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen)
  }

  const closeModal = e => {
    if (e.target.id === 'modal') {
      setModalIsOpen(false)
    }
  }

  const SocialSection = ({ twitter, telegram, website }) => (
    <div
      className="launchpad-social-section"
      style={{ flexDirection: 'row', alignItems: 'flex-start' }}
    >
      {twitter && (
        <a href={twitter} target="_blank" rel="noopener noreferrer">
          <img src={TwitterIcon} alt="Twitter" className="launchpad-social-icon" />
        </a>
      )}
      {website && (
        <a href={website} target="_blank" rel="noopener noreferrer">
          <img src={WebsiteIcon} alt="Website" className="launchpad-social-icon" />
        </a>
      )}
      {telegram && (
        <a href={telegram} target="_blank" rel="noopener noreferrer">
          <img src={TelegramIcon} alt="Telegram" className="launchpad-social-icon" />
        </a>
      )}
    </div>
  )

  const modalContent = (
    <div
      id="modal"
      onClick={closeModal}
      className={`modal ${modalIsOpen ? 'show-modal' : ''}`}
    >
      <div className="modal-content">
        <p>{description}</p>
        <button onClick={toggleModal} className="save-button">
          Close
        </button>
      </div>
    </div>
  )

  const copyAddress = address => async e => {
    e.stopPropagation()
    e.preventDefault()
    if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = address
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltip()
    }
  }

  function displayTooltip() {
    let timeoutId
    setIsTooltipDisplayed(true)
    timeoutId = setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }

  return (
    <div className="claim-card relative" style={{ display: 'flex' }}>
      <style jsx>{`
        .modal {
          opacity: 0;
          visibility: hidden;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          z-index: 10;
        }

        .show-modal {
          opacity: 1;
          visibility: visible;
        }

        .modal-content {
          background-color: #224132;
          padding: 20px;
          margin: 10px;
          border-radius: 5px;
          border: solid #096634 1px;
          text-align: center;
          z-index: 11;
          color: white;
          max-width: 480px;
        }
      `}</style>
      {modalContent}
      <div className='claimHeaderContentsBox'>
        <div className='claimHeaderContentBox'>
          <div className="claim-eth-logo-container">
            <div
              className="claim-eth-logo"
              style={{
                backgroundImage: bannerUrl
              }}
            />
          </div>
          <div className='claimHeaderBox'>
            <div className='claimHeaderContents'>
              <span className="claim-token-name">
                {tokenName} ($ {tokenSymbol})
              </span>
              <SocialSection
                twitter={twitter}
                telegram={telegram}
                website={website}
              />
            </div>
            <div className='claim-token-content-box'>
              <span className='createdBy' >
                Created by:
                <Link to={'/profile/?address=' + devAddress} className="CopyButton">
                  <span className="tokenLists" style={{ marginTop: '10px' }}>
                    {devAddress.slice(0, 5) + '..' + devAddress.slice(-3)}
                  </span>
                </Link>
              </span>
              <p>
                {' '}
                <span style={{ color: '#777', fontWeight: 'bold' }}>Contract: </span>
                <button onClick={copyAddress(contractAddress)} className="CopyButton">
                  <p className="launchpad-cap-type">
                    {isTooltipDisplayed ? (
                      <span className="tooltiptext">Copied!</span>
                    ) : (
                      <>
                        {contractAddress.slice(0, 5) + '..' + contractAddress.slice(-3)}
                        <img
                          src={CopyIcon}
                          alt=""
                          width="16px"
                          height="16px"
                          style={{ marginLeft: '4px', marginBottom: '-4px' }}
                        />
                      </>
                    )}
                  </p>
                </button>
              </p>
            </div>
            <p>{description}</p>
            <ChadPumpInfoSection
              tokenSupplyLiquidity={tokenSupplyLiquidity}
              tokenPrice={tokenPrice}
              tokenSupplyUSD={tokenSupplyUSD}
              tokenUnsoldTokens={tokenUnsoldTokens}
              maxBuyAmount={maxBuyAmount}
              volume={volume}
              tokenCreated={time}
            />
          </div>
        </div>
        <ChadPumpInfoMobileSection
          tokenSupplyLiquidity={tokenSupplyLiquidity}
          tokenPrice={tokenPrice}
          tokenSupplyUSD={tokenSupplyUSD}
          tokenUnsoldTokens={tokenUnsoldTokens}
          maxBuyAmount={maxBuyAmount}
          volume={volume}
          tokenCreated={time}
        />
      </div>

      {/* <button onClick={toggleModal} style={{ all: 'unset', cursor: 'pointer' }}>
        <img
          src={InfoIcon}
          alt=""
          width="32px"
          height="32px"
          style={{
            marginRight: '0px',
            marginTop: '10px',
            marginBottom: '-4px',
            position: 'absolute',
            filter: 'grayscale(100%)',
            top: '20px',
            right: '20px'
          }}
        />
      </button> */}
    </div>
  )
}

ClaimCard.propTypes = {
  tokenName: PropTypes.string.isRequired,
  Logo: PropTypes.element.isRequired,
  about: PropTypes.string.isRequired,
  tokenAddress: PropTypes.string.isRequired,
  contractAddress: PropTypes.string.isRequired,
  dexAddress: PropTypes.string.isRequired,
  devAddress: PropTypes.string.isRequired,
  dexName: PropTypes.string.isRequired,
  tokenSymbol: PropTypes.string.isRequired,
  tokenDecimals: PropTypes.number.isRequired,
  tokenTotalSupply: PropTypes.number.isRequired,
  tokenSupplyLiquidity: PropTypes.number.isRequired,
  tokenSupplyUSD: PropTypes.number.isRequired,
  tokenUnsoldTokens: PropTypes.string.isRequired,
  tokenCover: PropTypes.string.isRequired,
  website: PropTypes.string,
  telegram: PropTypes.string,
  twitter: PropTypes.string,
  volume: PropTypes.number,
  tokenCreated: PropTypes.string
}

export default ClaimCard
