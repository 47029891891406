import React from 'react';
import '../styles/Input.css'

interface TextAreaProps {
  value: string;
  rows: number;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  maxLength?: number;
}

const TextArea: React.FC<TextAreaProps> = ({ value, rows, onChange, placeholder, maxLength }) => {
  return (
    <div className="TextAreaContainer">
      <textarea
        className='TextArea'
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        required
      />
    </div>
  );
};

export default TextArea