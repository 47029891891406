import React from 'react';

const Footer = () => {
  return (
    <section className="footer" >
      <hr/>
      <p>
        <br />
        <span style={{ fontSize: '16px', color: '#bbbbbb' }}>
          Disclaimer: trading these tokens is highly risky / speculative. Do not invest more than you can afford to lose. Anyone can list a token, listing does not mean we endorse the token. Token prices can be extremely volatile. Be sure to follow any legal guidelines that your country specifies.
        </span>
      </p>
      <br />
      <p>
        <span className="chadfun">
        CamelPump.Fun
        </span>
      </p>
    </section>
  );
};

export default Footer;