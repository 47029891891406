import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const BalanceCard = ({
    name,
    symbol,
    tokenAddress,
    Logo,
    balance
}) => {
    const link = `/buy/?address=${tokenAddress}`
    return (
        <div className="balances-box">
            <Link to={link}>
                <div className='balanceContent'>
                    {Logo}
                    <div className="Balance-header-container">
                        <p className="launchpad-token-name left-aligned">{name}</p>
                        <div className='balanceContent'>
                            <p className="Balance-token-name left-aligned">{balance.toFixed(3)}</p>
                            <p className="Balance-token-name left-aligned">&nbsp;{symbol.toUpperCase()}</p>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

BalanceCard.propTypes = {
    name: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    tokenAddress: PropTypes.string.isRequired,
}

export default BalanceCard
