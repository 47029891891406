import React from 'react'
import PropTypes from 'prop-types'
import WebsiteIcon from '../icons/website.png'
import TelegramIcon from '../icons/telegram.png'
import TwitterIcon from '../icons/x-icon.svg'
import LaunchpadChangeUp from '../icons/launchpad-change-up.svg'
import Question from '../icons/question.svg'
import LaunchpadChangeDown from '../icons/launchpad-change-down.svg'
import { Link } from 'react-router-dom'
import topofchad from '../icons/topofchad.svg'

const LaunchpadCardHorizontal = ({
  progress,
  Liquidity,
  tokenName,
  Banner,
  chadAddress,
  contractAddress,
  devAddress,
  marketCap,
  website,
  twitter,
  telegram,
  symbol,
  description,
  changePercentage,
  info
}) => {
  const link = `/buy/?address=${chadAddress}`
  const progressText = progress > 100 ? 100 : progress

  const handleInnerLinkClick = (event) => {
    event.stopPropagation(); // Prevent the outer link from being activated
    const innerHref = event.currentTarget.getAttribute('data-href');
    if (innerHref) {
      window.location.href = innerHref;
    }
  };

  const SocialSection = ({ twitter, telegram, website }) => (
    <div
      className="launchpad-social-section"
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {twitter && (

        <a href={twitter} onClick={handleInnerLinkClick} target="_blank" rel="noopener noreferrer">
          <img
            src={TwitterIcon}
            alt="Twitter"
            className="launchpad-social-icon"
          />
        </a>
      )}
      {website && (
        <a href={website} onClick={handleInnerLinkClick} target="_blank" rel="noopener noreferrer">
          <img
            src={WebsiteIcon}
            alt="Website"
            className="launchpad-social-icon"
          />
        </a>
      )}
      {telegram && (
        <a href={telegram} onClick={handleInnerLinkClick} target="_blank" rel="noopener noreferrer">
          <img
            src={TelegramIcon}
            alt="Telegram"
            className="launchpad-social-icon"
          />
        </a>
      )}
    </div>
  )

  const getFormattedMarketCap = cap => {
    if (cap >= 1000000000000) return (cap / 1000000000000).toFixed(2) + 't'
    if (cap >= 1000000000) return (cap / 1000000000).toFixed(2) + 'b'
    if (cap >= 1000000) return (cap / 1000000).toFixed(2) + 'm'
    if (cap >= 1000) return (cap / 1000).toFixed(2) + 'k'
    return cap.toFixed(2)
  }

  return (
    <>
      <div className='topPeak'>
        {/* style={{ backgroundImage: `url(${topofchad})` }} */}
        <p className="h2 ContractContentTextTitle launchpad-card-horizontal-header">
          &nbsp;&nbsp;&nbsp;Trending Camel of the Hill
          &nbsp;
          <div
            className="tooltips"
          ><img src={Question} alt="" />
            <span className="tooltiptext">
              All meme coins with  the highest price increase will be automatically displayed here. Camel Pump is a decentralized platform and does not endorse any meme coin. (DYOR)!
            </span>

          </div>
        </p>
      </div>
      <Link to={link}>
        <div className="launchpad-card-horizontal relative">
          <div
            className="launchpad-banner-horizontal relative"
            style={{
              backgroundImage: `url(${Banner})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div
              className="launchpad-change-tag"
              style={{
                backgroundColor:
                  changePercentage >= 0
                    ? 'rgb(53, 255, 254)'
                    : 'rgb(255, 74, 138)'
              }}
            >
              {changePercentage >= 0 ? '+' : ''}
              {changePercentage}%
              <img
                src={
                  changePercentage >= 0 ? LaunchpadChangeUp : LaunchpadChangeDown
                }
                alt=""
              />
            </div>
          </div>
          <div className="launchpad-content">
            <div className="launchpad-header-container">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className="launchpad-created-by">Created by: </span>
                <span className="launchpad-dev-address">
                  {`${devAddress.slice(0, 4)}...${devAddress.slice(-4)}`}
                </span>
              </div>
              <SocialSection
                twitter={twitter}
                telegram={telegram}
                website={website}
              />
            </div>
            <div className="launchpad-dev-name-token-name">
              <p>
                {symbol} (${tokenName})
              </p>
            </div>
            <div className="launchpad-description">{description}</div>
            <br />
            <div className="launchpad-market-cap">
              <span style={{ color: 'rgb(153, 153, 153)' }}>Marketcap:</span>
              <span style={{ color: 'rgb(255, 255, 255)' }}>
                ${getFormattedMarketCap(marketCap)}
              </span>
              <span style={{ color: 'rgb(153, 153, 153)' }}>
                {' '}
                ({progressText.toFixed(2)}%)
              </span>
            </div>
            <div className="launchpad-progress-container">
              <div
                className="launchpad-progress-bar"
                style={{ width: `${progressText}%` }}
              />
            </div>
          </div>
        </div>
      </Link>
    </>
  )
}

LaunchpadCardHorizontal.propTypes = {
  progress: PropTypes.number.isRequired,
  Liquidity: PropTypes.number.isRequired,
  tokenName: PropTypes.string.isRequired,
  devAddress: PropTypes.string.isRequired,
  // devName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  changePercentage: PropTypes.number.isRequired,
  marketCap: PropTypes.number.isRequired
}

export default LaunchpadCardHorizontal
